.content_container {
  font-family: "SF Pro Text", -apple-system, BlinkMacSystemFont, Roboto,
    "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.show_container {
    z-index: 2;
    opacity: 1;
    visbility: visbile;
    transition: opacity 0.3s 0.3s;
}

.hide_container {
    z-index: -3;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.05s;
}

.MuiFab-root {
  position: absolute;
}


