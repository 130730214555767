:root {
  /*
  --swiper-pagination-color: rgba(28,28,30,0.25);
  --swiper-pagination-left: auto;
  --swiper-pagination-right: 8px;
  --swiper-pagination-top: auto;
  --swiper-pagination-fraction-color: inherit;
  --swiper-pagination-progressbar-bg-color: rgba(0,0,0,0.25);
  --swiper-pagination-progressbar-size: 4px;
  --swiper-pagination-bullet-size: 8px;
  --swiper-pagination-bullet-width: 8px;
  --swiper-pagination-bullet-height: 8px;
  --swiper-pagination-bullet-border-radius: 50%;
  --swiper-pagination-bullet-inactive-color: #000;
  --swiper-pagination-bullet-inactive-opacity: 0.2;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 4px;
  --swiper-pagination-bullet-vertical-gap: 6px;
  */
  /* --swiper-pagination-bullet-horizontal-gap: 0.5vh;
  --swiper-pagination-bullet-vertical-gap: 0.5vh; */
  --swiper-pagination-color: rgba(255, 255, 255, 0.25);
}

html,
body {
  position: relative;
  height: 100%;
}

body {
  background-size: cover;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #000;
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

.blurFilter {
  z-index: 1;
  width: 100vw;
  height: 100vh;
  position: fixed;
  pointer-events: none;
  will-change: filter;
  transform: translate3d(0,0,0);
}

.screenContainer {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 0;
}

.swiper {
  width: 100vw;
  height: 100vh;
  position: absolute;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background-color: transparent;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  height: 100vh;
}

.swiper-pagination {
  background-color: transparent;
  outline: none;
}

.player-wrapper {
  position: fixed;
  z-index: -1;
  overflow: hidden;
  width: 100%;
  height: 100%;
  transition: transform 0.3s;
}

.background_image_close {
  transform: translate3d(0,0,0) scale(1.2);
}

.tilt-wrapper {
  width: 100%;
  height: 100%;
}

.player-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* object-position: bottom 40% right 50%; */
}

#title_tile {
  background-color: #fefeac;
  font-size: 2vh;
}

.app_grid {
  height: 100%;
  display: grid;
  justify-content: center;
  align-items: start;
}

.dock {
  display: grid;
  justify-content: center;
  align-items: start;
  grid-template-columns: repeat(4, 12vw);
  grid-auto-rows: calc(80vh / 5);
}

.widget {
  aspect-ratio: 1;
}

.app_grid_zoom_container {
  transform-origin: center;
  transform-style: preserve-3d;
  perspective-origin: 50% 50%;
}
/* @media (min-width: 0px) {
  .swiper-pagination {
    margin-bottom: 80px;
  }
}

@media (min-width: 640px) {
  .swiper-pagination {
    margin-bottom: 100px;
  }
}

@media(min-width: 768px) {
  .swiper-pagination {
    margin-bottom: calc(0.78*110px);
  }
}

@media(min-width: 1024px) {
  .swiper-pagination {
    margin-bottom: calc(0.78*120px);
  }
}

@media(min-width: 1280px) {
  .swiper-pagination {
    margin-bottom: calc(0.78*140px);
  }
}

@media(min-width: 1920px) {
  .swiper-pagination {
    margin-bottom: calc(0.78*280px);
  }
} */
