/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.c-app-container {
  visibility: hidden;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  transform-origin: top left;
  width: 100%;
  min-height: 100vh;
  z-index: 2;
  background: #000;
}

.-app-open {
  visibility: visible;
  transition: transform 0.3s ease-in-out 0s, visibility 0.1s linear 0s,
    border-radius 0.3s;
  border-radius: 0;
}

.-app-close {
  visibility: hidden;
  transition: transform 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
    border-radius 0.3s;
  border-radius: 1.5rem;
}

.app_grid_app_open {
  /* -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8); */
  transform: perspective(250px) translate3d(0,0,-100px);
  animation-direction: normal;
  transition: transform 0.3s ease-in-out 0s;
}

.app_grid_app_close {
  /* -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1); */
  transform: perspective(250px) translate3d(0,0,0px);
  animation-direction: reverse;
  transition: transform 0.3s ease-in-out 0s;
}

.background_image_open {
  transform: translate3d(0,0,0px) scale(1.6);
}

.background_image_close {
  transform: translate3d(0,0,0px) scale(1.2);
}

.background_open {
  animation: blur 0.3s;
  animation-direction: normal;
}

.background_close {
  animation: revblur 0.3s;
  animation-direction: reverse;
}

@keyframes blur {
  0% {
    backdrop-filter: blur(0px);
  }
  50% {
    backdrop-filter: blur(20px);
  }
  100% {
    backdrop-filter: blur(40px);
  }
}

@keyframes revblur {
  0% {
    backdrop-filter: blur(0px);
  }
  50% {
    backdrop-filter: blur(20px);
  }
  100% {
    backdrop-filter: blur(40px);
  }
}
