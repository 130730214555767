@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
}

/* #root {
  height: 100%;
  width: 100%;
  position: absolute;
} */
